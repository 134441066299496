/* eslint-disable no-nested-ternary */
import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Trip1 from '@/assets/img/tmp/donors/field_trip_img1@2x.jpg';
import Trip2 from '@/assets/img/tmp/donors/field_trip_img2@2x.jpg';
import Trip3 from '@/assets/img/tmp/donors/field_trip_img3@2x.jpg';
import Trip4 from '@/assets/img/tmp/donors/field_trip_img4@2x.jpg';
import AdminBanner from '@/components/AdminBanner';
import ButtonMoreView from '@/components/ButtonMoreView';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import { TabData } from '@/components/LegacyTab';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import Youtube from '@/components/Youtube';
import { BOARD_KIND_FIELDTRIP } from '@/helpers/BoardHelper';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { navigate, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const tab: TabData[] = [
  {
    id: 0,
    youtubeId: `J8pZlzdjWJI`,
    title: `라오스 편`,
  },
  {
    id: 1,
    youtubeId: `ASdoi5YvS3Y`,
    title: `DR콩고 편`,
  },
  {
    id: 2,
    youtubeId: `FJCGMLRInfo`,
    title: `니제르 편`,
  },
  {
    id: 3,
    youtubeId: `VBy7D6Enkyo`,
    title: `몽골 편`,
  },
  {
    id: 4,
    youtubeId: `Q0BSx_gbPbw`,
    title: `캄보디아 편`,
  },
];

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `후원자관계팀`,
    tel: `02-737-1004`,
    email: `donor@unicef.or.kr`,
  },
];

const YoutubeTab = styled(Youtube)`
  .UiTabPanel > li {
    width: auto !important;
    display: inline-block;
  }
`;

const SectionTop = styled(Section)``;

const TripFlex = styled.div`
  dl {
    display: flex;
    align-items: center;
    margin: 0 -16px;
    margin-bottom: 64px;

    dt,
    dd {
      padding: 0 16px;
      width: 50%;
    }

    dd {
      p {
        line-height: 2;
        margin-top: 16px;
      }
      ${ButtonMoreView} {
        width: 100%;
        max-width: 320px;
        margin-top: 48px;
      }
    }
    &:nth-child(2n + 0) {
      flex-direction: row-reverse;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  ${breakpoint(`mobile`)} {
    margin: 0 -20px;
    dl {
      flex-wrap: wrap;
      margin-bottom: 40px;

      dt,
      dd {
        width: 100%;
      }
      dd {
        .dec-wrap {
          padding: 24px 20px;
          padding-bottom: 0;
        }
        ${ButtonMoreView} {
          max-width: 100%;
          margin-top: 40px;
        }
      }
    }
  }
`;
const SectionFlex = styled(Section)`
  padding-bottom: 96px;

  ${breakpoint(`mobile`)} {
    padding-bottom: 72px;
  }
`;

const SectionBn = styled(Section)``;

const SectionContact = styled(Section)`
  padding-top: 96px;
`;

const FieldTrip: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: [BOARD_KIND_FIELDTRIP],
        pagePerCount: 8,
        category: ['A031'],
      });
      const thumbList = data as any;
      setThumbs(
        thumbList.map((list: BoardVo) => ({
          id: list.boardIndexNumber,
          title: list.subject,
          pcImage: list.imgAttGrpNoTn,
          mobileImage: list.imgAttGrpNoTn,
          link: `/what-we-do/news/${list.boardIndexNumber}`,
          date: list.firstRegisterDate,
          boardCategoryCode: list.boardCategoryCode,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="현장 방문"
      description="for every child, field visits"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  유니세프의 사업현장은 <br />
                  어떤 모습일까요?
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                공모전을 통해 후원하면서 느끼는 소감을 글로 보내주신 분들 중
                선정하여 현장 방문의 기회를 제공합니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <YoutubeTab className="youtube-container" tabs={tab} />
        </Container>
      </SectionTop>

      <SectionFlex>
        <Container>
          <TripFlex>
            <dl>
              <dt>
                <Image pcSrc={Trip1} mobileSrc={Trip1} />
              </dt>
              <dd>
                <div className="dec-wrap">
                  <strong className="dec">
                    “유니세프는 단기적인 해결책보다는 장기적으로 근본적인 문제
                    해결 정책을 만든다는 느낌을 받았습니다. 사람들을 교육하고
                    스스로 다른 사람을 도울 수 있도록 사업을 진행하고
                    있었습니다. ”
                  </strong>
                  <p>- 몽골 후원자 현장 방문에 참여한 후원자님</p>
                  <ButtonMoreView
                    mode="skyTransparent"
                    onClick={() => navigate(`/involve/donors/field-trip/84110`)}
                  >
                    자세히 보기
                  </ButtonMoreView>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image pcSrc={Trip2} mobileSrc={Trip2} />
              </dt>
              <dd>
                <div className="dec-wrap">
                  <strong className="dec">
                    “니제르의 하루를 온라인을 통해 경험할 수 있게 되어 뜻 깊은
                    시간이 되었고 기쁜 마음이 들었습니다. 영양실조치료식
                    생산과정과 영양실조 어린이에게 어떻게 공급되는지 잘 알게
                    되었습니다. 후원자로서 뿌듯한 마음이 들었고 제가 도움이 될
                    수 있다는 것에 감사함을 느낄 수 있었습니다. 앞으로도 꾸준히
                    후원자로서의 역할을 되새기며 살겠습니다.”
                  </strong>
                  <p>- 니제르 온라인 현장 방문에 참여한 후원자님</p>
                  <ButtonMoreView
                    mode="skyTransparent"
                    onClick={() =>
                      navigate(`/involve/donors/field-trip/131325`)
                    }
                  >
                    자세히 보기
                  </ButtonMoreView>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image pcSrc={Trip3} mobileSrc={Trip3} />
              </dt>
              <dd>
                <div className="dec-wrap">
                  <strong className="dec">
                    “저에게는 매달 이체되는 후원금일 뿐이지만 니제르에 있는
                    아이들에게는 생명줄의 의미가 될 수 있겠다는 걸 다시금 깨닫게
                    해주었습니다. 작은 후원이지만 아이들에게 희망을 줄 수
                    있었으면 좋겠습니다. 이 작은 힘을 여러 명이 보탠다면 아주 큰
                    힘이 될 수 있다는 것을 모두가 함께 느끼고 움직였으면 하는
                    바람입니다.”
                  </strong>
                  <p>- 니제르 후원자 현장 방문에 참여한 후원자님</p>
                  <ButtonMoreView
                    mode="skyTransparent"
                    onClick={() =>
                      navigate(`/involve/donors/field-trip/131325`)
                    }
                  >
                    자세히 보기
                  </ButtonMoreView>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <Image pcSrc={Trip4} mobileSrc={Trip4} />
              </dt>
              <dd>
                <div className="dec-wrap">
                  <strong className="dec">
                    “현장에서 어떠한 지원이 필요한지 파악하여 사업을 시작하는
                    유니세프의 사업선정 능력에 감탄했다. 유니세프의 '현장에서의
                    필요 파악'과 '현장에 맞는 사업추진' 정책과 그 실행에 경의를
                    표한다. 이번 방문을 통해 후원금이 유니세프를 통해 더 나은
                    세상을 만든다는 믿음이 생겼다. ”
                  </strong>
                  <p>- 라오스 후원자 현장 방문에 참여한 후원자님</p>
                  <ButtonMoreView
                    mode="skyTransparent"
                    onClick={() => navigate(`/what-we-do/news/183727`)}
                  >
                    자세히 보기
                  </ButtonMoreView>
                </div>
              </dd>
            </dl>
          </TripFlex>
        </Container>
      </SectionFlex>

      <SectionBn>
        <Container>
          <AdminBanner idx="0" kind="0001" />
        </Container>
      </SectionBn>

      <SectionContact className="by-sub-main-layout">
        <Container>
          <header>
            <h2 className="a11y">문의</h2>
          </header>
          <ContactUs itemData={contactData} />
        </Container>
      </SectionContact>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A031" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default FieldTrip;
